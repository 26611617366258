import { useEffect } from 'react';
import { EBrand } from '../../typings/enums/Brands';

type TStatusWrapper = {
  status: number;
  componentsForStatus?: { [status: string]: JSX.Element };
  defaultNotFound: JSX.Element;
  children: JSX.Element;
  isReady?: boolean;
  brand?: string;
};

export const StatusWrapper = ({
  status,
  componentsForStatus,
  defaultNotFound,
  children,
  brand = EBrand.Aveda,
}: TStatusWrapper) => {
  // redirect after 3 seconds
  useEffect(() => {
    if (status === 200) {
      return;
    }
    const redirectUrl =
      brand === EBrand.Aveda ? 'https://www.aveda.com' : 'https://www.maccosmetics.com/';
    const timer = setTimeout(() => {
      window.location.replace(redirectUrl);
    }, 1500);

    return () => clearTimeout(timer);
  }, [status]);

  if (status === 200) {
    return children;
  }
  return componentsForStatus?.[status] || defaultNotFound;
};
