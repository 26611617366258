import { TArtist } from '@elc/common';

export const useArtistBackNavigation = (
  artist: TArtist,
): {
  label: string;
  path: string;
} | null => {
  if (!artist.locations.length) {
    return null;
  }

  return {
    label: artist.locations[0].name,
    path: `/salon/${artist.locations[0].vanity_url}`,
  };
};
