import React from 'react';
import { referrerInstance, FeaturedArtistsPage, FromShortLink } from '@elc/common';
import { Route, Routes as Switch } from 'react-router-dom';
import About from '../screens/About';
import ArtistScreen from '../screens/Artist/Artist';
import Layout from '../components/layout/Layout';
import Location from '../screens/Location';
import LookScreen from '../screens/Look/Look';
import Page404 from '../screens/Page404';
import StoreLayout from '../components/layout/storeLayout';
import Terms from '../screens/Terms';
import { pages } from './config';
import LearningCenter from '../screens/LearningCenter';
import LearningCenterPost from '../screens/LearningCenterPost';
import Faq from '../screens/Faq';
import { ProductScreen } from '../screens/Product';
import { SalonGroupPage } from '../screens/SalonGroupPage/SalonGroupPage';
import { featureFlags } from '../constants/featureFlags';
import DeleteAccount from '../screens/DeleteAccount';

const Routes = (): JSX.Element => {
  referrerInstance.setReferrer();
  return (
    <Switch>
      <Route element={<StoreLayout />}>
        <Route path={pages.BUNDLE} element={<LookScreen />} />
        <Route path={pages.LOOK_FROM_ARTIST} element={<LookScreen />} />
        <Route path={pages.LOOK_FROM_LOCATION} element={<LookScreen />} />
        <Route path={pages.ARTIST_FROM_LOCATION} element={<ArtistScreen />} />
        <Route path={pages.ARTIST} element={<ArtistScreen />} />
        <Route path={pages.PRODUCT} element={<ProductScreen />} />
        <Route path={pages.PRODUCT_FROM_LOCATION} element={<ProductScreen />} />
        <Route path={pages.ROOT} element={<FeaturedArtistsPage />} />
      </Route>
      <Route path={pages.LOCATION} element={<Location />} />
      {featureFlags.enableOwnerGroupPage && (
        <Route path={pages.SALON_GROUP} element={<SalonGroupPage />} />
      )}

      <Route element={<Layout />}>
        <Route path={pages.FROM_LINK} element={<FromShortLink />} />
        <Route path={pages.FAQ} element={<Faq type="customers" />} />
        <Route path={pages.FAQ_CONTENT_CREATORS} element={<Faq type="contentCreators" />} />

        <Route path={pages.ABOUT} element={<About />} />
        <Route path={pages.TERMS_OF_SERVICE} element={<Terms />} />
        <Route path={pages.LEARNING_CENTER_POST} element={<LearningCenterPost />} />
        <Route path={pages.LEARNING_CENTER} element={<LearningCenter />} />
        <Route path={pages.DELETE_ACCOUNT} element={<DeleteAccount />} />
        <Route path="*" element={<Page404 />} />
      </Route>
    </Switch>
  );
};

export default Routes;
