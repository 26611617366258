import styled from 'styled-components';
import { transparentButton } from '../../utils/styled-components/mixins';
import Container from '../Container/Container';
import { media } from '../../utils/rwd';

export const SalonTopbarWrapper = styled.div<{ $hidden: boolean }>`
  background: white;
  position: sticky;
  top: 0;
  z-index: 102;
  width: 100%;
  transform: translateY(${({ $hidden }) => ($hidden ? '-200px' : '0')});
  transition: 0.4s;

  & * {
    font-family: FFMeta, Arial, Helvetica, sans-serif;
  }
`;

export const SalonTopbarContainer = styled.div<{ $fullWidth: boolean }>`
  ${media.lg`
    max-width:  ${({ $fullWidth }) => ($fullWidth ? 'unset' : '1440px')};
    padding: ${({ $fullWidth }) => ($fullWidth ? '0' : '0 80px')};
    box-sizing: border-box;
    margin: 0 auto;
  `}
`;

export const SalonTopbar = styled(Container)<{ $linksHidden: boolean }>`
  font-family: ${({ theme }) => theme.fontFamily.bold};
  padding-top: ${({ $linksHidden }) => ($linksHidden ? '10px' : '40px')};
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${media.lg`
    display:flex;
  `}
`;

export const LogosWrapper = styled.div<{ $fullWidth: boolean }>`
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'unset')};
  margin-bottom: ${({ $fullWidth }) => ($fullWidth ? '25px' : 'unset')};
  display: ${({ $fullWidth }) => ($fullWidth ? 'grid' : 'flex')};
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  align-items: center;
  justify-items: center;
`;

export const LinksWrapper = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};

  flex: 1;
  justify-content: center;
  gap: 25px;
  font-weight: 1000;

  &:has(.hoveredOn) * {
    color: #a39e9d;
  }
`;

export const BrandLogo = styled.img<{ $fullWidth: boolean }>`
  height: ${({ $fullWidth }) => ($fullWidth ? '55px;' : '16px')};
  margin-right: 5px;
  cursor: pointer;
  ${media.sm`
    margin-right: 10px;
  `}
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 24px;
  align-items: flex-end;
  ${media.sm`
    gap: 33px;
  `}
`;

export const BurgerMenu = styled.button`
  ${transparentButton}
  filter: invert(1);
`;

export const Logo = styled.img<{ $fullWidth: boolean }>`
  height: ${({ $fullWidth }) => ($fullWidth ? '55px;' : '30px')};
  margin-right: 10px;
  padding: 3px;
  background-color: #120e02;
  justify-self: start;

  ${media.sm`
    margin-right: 20px;
  `}
`;

export const Link = styled.button<{ $color: string; $isSmall: boolean }>`
  ${transparentButton}
  padding: 0;
  color: ${(props) => props.$color};
  text-transform: uppercase;
  white-space: nowrap;
  font-size: ${({ $isSmall }) => ($isSmall ? '10px' : '14px')};
  font-weight: inherit;
  line-height: 14px;

  &.hoveredOn {
    color: ${(props) => props.$color};
  }
`;

export const FullWidthBar = styled.div<{ $backgroundColor: string }>`
  width: 100%;
  background-color: ${(props) => props.$backgroundColor};
  display: flex;
  justify-content: center;
  padding: 4px 0 8px;
  font-weight: 400;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.8px;
`;

export const AbsoluteFullWidthBar = styled(FullWidthBar)`
  position: absolute;
`;

export const BarText = styled.p<{ $color: string; $fontWeight?: number }>`
  margin-bottom: 0;
  color: ${(props) => props.$color};
  font-weight: ${(props) => props.$fontWeight || 400};
`;
