import React, { useEffect } from 'react';
import { FavoritesList, TFavorite, useAddProductToCart, useQueryParams } from '@elc/common';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import getOrFp from 'lodash/fp/getOr';

import { useGlobalState, useThunkDispatch, GlobalState } from '../../store';
import {
  artistFavoritesSelector,
  areFavoritesLoadingSelector,
} from '../../store/selectors/artist.selectors';
import { fetchArtistFavorites } from '../../store/actions/artist.actions';

export const Favorites = () => {
  const dispatch = useThunkDispatch();
  const favorites = useSelector(artistFavoritesSelector);
  const areFavoritesLoading = useSelector(areFavoritesLoadingSelector);
  const { token = '', owner_token: ownerPageToken } = useQueryParams();

  const productToken = token || ownerPageToken;
  const addToCart = useAddProductToCart();
  const navigate = useNavigate();

  const { item: artist } = useGlobalState(getOrFp({} as GlobalState['artist'], 'artist'));

  useEffect(() => {
    dispatch(fetchArtistFavorites());
  }, []);

  const onProductClick = (favorite: TFavorite) => {
    // @ts-ignore
    navigate(
      `/a/${artist.vanity_url}/p/${favorite.lookId}${
        productToken ? `?owner_token=${productToken}` : ''
      }`,
      {
        state: {
          from: 'artist',
        },
      },
    );
  };

  // to do: add click handler
  return (
    <FavoritesList
      onProductClick={onProductClick}
      addToCart={addToCart}
      favorites={favorites || []}
      loading={areFavoritesLoading}
    />
  );
};

export default Favorites;
