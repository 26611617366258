import React from 'react';
import { useTranslation } from 'react-i18next';

import * as Ui from './SalonHeader.styles';
import { ILocation } from '../../typings/interfaces/Location';
import { getAvailableSocialsLinks } from '../../utils/availableSocials';
import { ReactComponent as DiscoveryIcon } from '../../assets/svg/discovery.svg';
import { ReactComponent as MessageIcon } from '../../assets/svg/message.svg';
import { ReactComponent as CalendarIcon } from '../../assets/svg/calendar.svg';
import { E2EWrapper } from '../E2EWrapper/E2EWrapper';
import { getLocationAddress } from '../../utils/getLocationAddress';
import AvedaPlaceHolder from '../../assets/images/aveda-placeholder.png';
import { HtmlTruncatedText } from '../TruncateText/HtmlTruncatedText';

type SalonHeaderProps = {
  salon: ILocation;
  className: string;
};

const formatPhone = (phone: string) => {
  const lastChar = phone.substring(phone.length - 1, phone.length);

  return lastChar === '-' ? phone.substring(0, phone.length - 1) : phone;
};

export const SalonHeader = ({ salon, className }: SalonHeaderProps) => {
  const { t } = useTranslation(['components']);
  const socials = getAvailableSocialsLinks(salon);

  const salong_cover_photo = salon.cover_photo_id ? salon.cover_photo : AvedaPlaceHolder;

  return (
    <Ui.Wrapper className={className}>
      <Ui.Container>
        <Ui.Row>
          <Ui.ImageCol>
            <Ui.Image alt="salon photo" src={salong_cover_photo} />
          </Ui.ImageCol>

          <Ui.ContentColumn>
            <E2EWrapper id="salonName">
              <Ui.SalonName>{salon.name}</Ui.SalonName>
            </E2EWrapper>
            <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
              <Ui.Classification>{salon.classification}</Ui.Classification>
            </div>

            <Ui.Bio>
              <HtmlTruncatedText html={salon.bio} elementsCount={3} />
            </Ui.Bio>

            <Ui.ContactData>
              <E2EWrapper id="salonAddress">
                <Ui.SalonAddress>{getLocationAddress(salon)}</Ui.SalonAddress>
              </E2EWrapper>

              <Ui.PhoneWrapper>
                <E2EWrapper id="salonNumber">
                  <p>{formatPhone(salon.phone_primary)}</p>
                </E2EWrapper>
              </Ui.PhoneWrapper>
            </Ui.ContactData>

            <Ui.IconsContainer>
              {salon.website_url && (
                <E2EWrapper id="salonWebsiteButton">
                  <Ui.ExternalLink href={salon.website_url} target="_blank" rel="noopener">
                    <Ui.ContactIcon>
                      <DiscoveryIcon />
                    </Ui.ContactIcon>
                    <Ui.IconCaption>{t('website')}</Ui.IconCaption>
                  </Ui.ExternalLink>
                </E2EWrapper>
              )}
              {salon.book_online_url && (
                <E2EWrapper id="salonBookingButton">
                  <Ui.ExternalLink href={salon.book_online_url} target="_blank" rel="noopener">
                    <Ui.ContactIcon>
                      <MessageIcon />
                    </Ui.ContactIcon>
                    <Ui.IconCaption>{t('booking')}</Ui.IconCaption>
                  </Ui.ExternalLink>
                </E2EWrapper>
              )}
              {salon.book_email && (
                <E2EWrapper id="salonEmailButton">
                  <Ui.MailLink href={`mailto:${salon.book_email}`}>
                    <Ui.ContactIcon>
                      <CalendarIcon />
                    </Ui.ContactIcon>
                    <Ui.IconCaption>{t('email')}</Ui.IconCaption>
                  </Ui.MailLink>
                </E2EWrapper>
              )}
            </Ui.IconsContainer>

            <Ui.Socials socials={socials} />
          </Ui.ContentColumn>
        </Ui.Row>
      </Ui.Container>
    </Ui.Wrapper>
  );
};
