import styled from 'styled-components';

import {
  Tabs as TabsComponent,
  BackNavigation as BackNavigationComponent,
  media,
} from '@elc/common';

export const ContentWrapper = styled.div`
  width: 100%;

  ${media.lg`
    max-width: 1440px;
    padding: 0 80px;
    box-sizing: border-box;
    margin: 0 auto;
  `}
`;

export const Tabs = styled(TabsComponent)`
  margin-top: 20px;
  font-size: 12px;
  font-weight: 400;
  font-family: MetaOTBold;
  ${media.lg`
    margin-top: 0px;
`}
`;

export const BackNavigation = styled(BackNavigationComponent)`
  position: relative;
  padding-left: 12px;
  ${media.lg`
  padding-left:  12px;

  `}
`;

export const BackNavigationWrapper = styled(ContentWrapper)`
  height: auto;
  ${media.lg`
   height: 0;
  `}
`;
