import getOrFp from 'lodash/fp/getOr';
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useMatch, useNavigate, useParams } from 'react-router-dom';

import {
  analyticsPromoView,
  analyticsSetPageDataArtist,
  analyticsTabSelected,
  ArtistProfileSection,
  breakpoints,
  CenteredCard,
  createLookLink,
  EBrand,
  Feedback,
  LooksList,
  StatusWrapper,
  TRelatedContent,
  useAddProductToCart,
  useQueryParams,
  useScrollToTop,
  WelcomePopup,
} from '@elc/common';
import { useTranslation } from 'react-i18next';

import { useMediaQuery } from 'react-responsive';
import { BasketIcon } from '../../components/basket/basket';
import { Favorites } from '../../components/Favorites/Favorites';
import { Metatags } from '../../components/Metatags';
import { useArtistBackNavigation } from '../../hooks/useArtistBackNavigation';
import { useStoreLayoutContext } from '../../hooks/useStoreLayoutContext';
import { GlobalState, useGlobalState, useThunkDispatch } from '../../store';
import {
  fetchArtist,
  fetchArtistLook,
  fetchPrivateArtistProfile,
} from '../../store/actions/artist.actions';
import {
  artistFavoritesLenghtSelector,
  artistLooksSelector,
  isArtistLoadedSelector,
  relatedContentSelector,
} from '../../store/selectors/artist.selectors';
import { bundleAction } from '../../store/slices/bundle.slice';
import { increaseEntriesCounter } from '../../utils/increaseEntriesCounter';
import * as Ui from './Artist.styles';
import { pages } from '../../routes/config';

type ArtistRouteParams = { vanity_url: string; locationName?: string };

const ArtistScreen: FC = () => {
  const { vanity_url = '', locationName = '' } = useParams<ArtistRouteParams>();
  const dispatch = useThunkDispatch();
  const navigate = useNavigate();
  const isSmallerThenMd = useMediaQuery({
    query: `(max-width: ${breakpoints.md}px)`,
  });
  const { token = '', preview, feedback: addFeedback, from_location } = useQueryParams();
  const navigatedFromLocation = from_location === '1';
  const { t } = useTranslation(['app']);
  const { setLogo, showAvedaTopBar } = useStoreLayoutContext();
  const isArtistFromLocation = useMatch(pages.ARTIST_FROM_LOCATION) !== null;

  const { status, item: artist } = useGlobalState(getOrFp({} as GlobalState['artist'], 'artist'));
  const isArtistLoaded = useSelector(isArtistLoadedSelector);
  const looks = useSelector(artistLooksSelector);
  const favoritesNumber = useSelector(artistFavoritesLenghtSelector);
  const relatedContent = useSelector(relatedContentSelector);
  const addProductToCart = useAddProductToCart();
  const backNavigationProps = useArtistBackNavigation(artist);

  const isDataReady = artist.vanity_url === vanity_url || status !== 200;

  useScrollToTop();

  useEffect(() => {
    analyticsSetPageDataArtist(vanity_url);
  }, [vanity_url]);

  useEffect(() => {
    if (token) {
      dispatch(fetchPrivateArtistProfile({ vanity_url, token }));
    } else {
      dispatch(fetchArtist(vanity_url));
    }
  }, [vanity_url, token, preview]);

  useEffect(() => {
    if (!artist.vanity_url || !isDataReady) {
      return;
    }

    if (!preview) {
      increaseEntriesCounter(artist.uid, 'PROFILE', navigatedFromLocation ? 'SALON' : undefined);
    }

    if (artist.is_brand_owner) {
      if (artist?.locations) {
        setLogo(artist?.locations[0]?.logo);
      }
    } else {
      setLogo('');
    }

    // @ts-ignore
    analyticsPromoView(relatedContent.map((c, idx) => [c, idx + looks.length + 1]));
  }, [artist.vanity_url]);

  if (!artist) {
    return null;
  }

  const handleShopNowClick = (look) => {
    dispatch(bundleAction.setPendingStatusForLook());
    navigate(
      createLookLink(look, preview === 'true', !!addFeedback, token, {
        artistName: artist.vanity_url,
        locationName: isArtistFromLocation ? locationName : '',
      }),
      {
        state: {
          from: 'artist',
        },
      },
    );
  };

  const getTabsNames = () => {
    const lookName =
      looks.length === 1
        ? `${looks.length} ${t('pages:artist.tabs.looks.singular')} `
        : `${looks.length} ${t('pages:artist.tabs.looks.plural')}`;

    const favoriteName =
      favoritesNumber === 1
        ? `${favoritesNumber} ${t('pages:artist.tabs.favorites.singular')} `
        : `${favoritesNumber || ''} ${t('pages:artist.tabs.favorites.plural')} `;

    return [
      { text: lookName, id: 'looks' },
      { text: favoriteName, id: 'favorites' },
    ];
  };

  if (!isDataReady) {
    return null;
  }

  const artistLocationsBusinessIds = artist.locations
    .map((location) => location.businessId)
    .join(', ');
  const artistLooksShortIds = artist.stores.map((store) => store.short_id).join(', ');
  const keywords = `${t('metatags:keywords')}, ${artist.vanity_url}, ${artist.fullName}${
    artistLocationsBusinessIds ? `, ${artistLocationsBusinessIds}` : ''
  }${artistLooksShortIds ? `, ${artistLooksShortIds}` : ''}`;

  return (
    <StatusWrapper
      status={status}
      componentsForStatus={{
        403: (
          <CenteredCard
            title={t('app:errors.accessDenied')}
            content={t('app:errors.tokenNeeded')}
          />
        ),
      }}
      defaultNotFound={
        <CenteredCard title={t('app:errors.notFound')} content={t('app:errors.artistNotFound')} />
      }
    >
      <>
        <Metatags
          title={artist.fullName}
          description={artist.about}
          image={artist.picture}
          keywords={keywords}
        />
        {addFeedback && token && <Feedback uid={artist.uid} token={token} />}
        <WelcomePopup />
        {!!backNavigationProps && (
          <Ui.BackNavigationWrapper>
            <Ui.BackNavigation {...backNavigationProps} />
          </Ui.BackNavigationWrapper>
        )}

        <ArtistProfileSection
          showLogo={!showAvedaTopBar}
          artist={artist}
          ready={isArtistLoaded}
          brand={EBrand.Aveda}
        >
          <BasketIcon />
        </ArtistProfileSection>

        <Ui.ContentWrapper>
          <Ui.Tabs
            displayAs={isSmallerThenMd ? 'tabs' : 'buttons'}
            tabs={getTabsNames()}
            tabSelectedCallback={(idx) => {
              if (idx === 0) analyticsTabSelected('looks');
              else analyticsTabSelected('favorites');
            }}
          >
            <LooksList
              onShopNowClick={handleShopNowClick}
              looks={looks || []}
              fetchArtistLook={fetchArtistLook}
              // @ts-ignore
              relatedContent={isDataReady ? relatedContent : ([] as TRelatedContent[])}
              addProductToCart={addProductToCart}
            />
            <Favorites />
          </Ui.Tabs>
        </Ui.ContentWrapper>
      </>
    </StatusWrapper>
  );
};
export default ArtistScreen;
